import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export default function DelayedRendered({
  delay,
  waitFor,
  height,
  children,
}: {
  delay: number;
  waitFor: boolean;
  height: number;
  children: any;
}) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (waitFor === true) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    }
  }, [delay, waitFor]);

  return show ? children : <Skeleton height={height} />;
}
