import { useClient } from 'context/auth-context';
import { useMutation } from 'react-query';
import { queue } from '../../common/notification';

function usePointDataDownload() {
  const client = useClient();

  return useMutation(async ({ pointId, start, end, dataType, filteredAttributes }: any) => {
    if (!start || !end) {
      queue.notify({
        body: 'Vui lòng chọn ngày khi tải dự liệu',
        dismissesOnAction: true,
        icon: 'check',
      });
      return;
    }

    const startQuery = start ? `start=${start}` : '';
    const endQuery = end ? `end=${end}` : '';
    const type = dataType ? `type=${dataType}` : null;
    const attrs = filteredAttributes ? `attrs=${filteredAttributes}` : null;

    const res = await client(
      `point/${pointId}/download?${startQuery}&${endQuery}&${type}&${attrs}`,
      {
        method: 'GET',
      }
    );

    queue.notify({
      body: res.message,
      dismissesOnAction: true,
      icon: 'check',
    });
  });
}

export { usePointDataDownload };
