import { useClient } from 'context/auth-context';
import { useMutation } from 'react-query';
import { queue } from '../../common/notification';

function useReportDownload() {
  const client = useClient();

  return useMutation(async ({ start, end, attributes, type }: any) => {
    const startQuery = start ? `start=${start}` : '';
    const endQuery = end ? `end=${end}` : '';
    const attributesQuery = attributes && attributes.length ? attributes : '';

    const res = await client(
      `user/report?${startQuery}&${endQuery}&attributes=${attributesQuery}&type=${type}&export=true`,
      {
        method: 'GET',
      }
    );

    queue.notify({
      body: res.message,
      dismissesOnAction: true,
      icon: 'check',
    });
  });
}

export { useReportDownload };
