import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.4375rem 1.25rem;
  text-shadow: none;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #f1f2f3;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
`;

function Button(props) {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}

export default Button;
