import Text from '@Atomics/Text';
import { CollapsibleList, List, SimpleListItem } from '@rmwc/list';
import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/list/styles';
import '@rmwc/tooltip/styles';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { IconMap } from './IconMap';
import { IconMapPin } from './IconMapPin';
import { ReactComponent as HomeIcon } from './home.svg';
import { IconReport } from '@Organisms/SideMenu/IconReport';
import { IconMapBig } from '@Organisms/SideMenu/IconMapBig';

export const ICON_MAP = 'iconMap';
export const ICON_MAP_PIN = 'iconMapPin';
export const ICON_HOME = 'HomeIcon';
export const ICON_REPORT = 'ReportIcon';
export const ICON_MAP_BIG = 'IconMapBig';

const iconNameToIcon = {
  [ICON_MAP]: IconMap,
  [ICON_REPORT]: IconReport,
  [ICON_MAP_BIG]: IconMapBig,
  [ICON_MAP_PIN]: IconMapPin,
  [ICON_HOME]: (
    <HomeIcon
      css={`
        margin: 0;
      `}
    />
  ),
};

interface SideMenuItem {
  label: string;
  path: string;
  icon?: string;
  items?: SideMenuItem[];
}

interface SideMenu {
  data: { items: SideMenuItem[] };
  show: boolean;
}

const StyledText = styled(Text)`
  font-size: 14px;
  color: #2b2b2b;
  margin-left: 10px;
  /* font-weight: 500; */
`;

const StyledItem = styled(({ children, ...props }) => (
  <SimpleListItem {...props}>{children}</SimpleListItem>
))`
  border-radius: 3px;
  transition: all 100ms;
  &:hover {
    background-color: var(--side-menu-item-on-hover);
  }
  &:active,
  &:focus {
    background-color: white;
  }
`;

const StyledList = styled(List)<any>`
  min-width: ${(props) => (props.$show ? 'var(--side-menu-width)' : '77px')};
  background-color: var(--side-menu-background);
  padding: 21px var(--side-menu-padding);
  border-right: 1px solid #e0e6ed;
  position: relative;
  top: var(--nav-size);
  left: 0;
  height: calc(100vh - var(--nav-size));
  overflow-y: auto;

  &::after {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.2) 0px,
      rgba(0, 0, 0, 0.2) 1px,
      rgba(0, 0, 0, 0.1) 1px,
      rgba(0, 0, 0, 0) 100%
    );
    bottom: 0px;
    right: 0;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    top: 0px;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    width: 5px;
    content: '';
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: currentColor;
    stroke-width: 2;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &&& {
    .mdc-list-item {
      &::before,
      &::after {
        background-color: transparent;
      }
    }
  }

  .mdc-list-item--activated {
    ${StyledText} {
      color: var(--primary);
    }

    ${StyledItem} {
      background-color: var(--side-menu-item-on-hover);
    }

    svg {
      stroke: var(--primary);
    }
  }
`;

export default function SideMenu({ data, show }: SideMenu) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledList $show={show}>
      {data.items.map((item) =>
        item.items?.length ? (
          <CollapsibleList
            key={item.label}
            open={isOpen}
            handle={
              show ? (
                <StyledItem
                  graphic={item.icon ? iconNameToIcon[item.icon] : undefined}
                  ripple={false}
                  text={<StyledText>{item.label}</StyledText>}
                  metaIcon="chevron_right"
                />
              ) : (
                <Tooltip content={item.label} align="right" showArrow>
                  <StyledItem
                    ripple={false}
                    text=""
                    css={`
                      justify-content: center;
                      color: var(
                        --mdc-theme-text-icon-on-background,
                        rgba(0, 0, 0, 0.38)
                      );
                    `}
                  >
                    {item.icon ? iconNameToIcon[item.icon] : undefined}
                  </StyledItem>
                </Tooltip>
              )
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {item.items?.map((i) => (
              <NavLink
                exact
                to={i.path}
                key={i.label}
                onClick={(e) => e.stopPropagation()}
                activeClassName="mdc-list-item--activated"
              >
                {show ? (
                  <StyledItem
                    graphic={i.icon ? iconNameToIcon[i.icon] : undefined}
                    ripple={false}
                    text={<StyledText>{i.label}</StyledText>}
                  />
                ) : (
                  <Tooltip content={i.label} align="right" showArrow>
                    <StyledItem
                      graphic={i.icon ? iconNameToIcon[i.icon] : undefined}
                      ripple={false}
                      text=""
                      css={`
                        justify-content: center;
                      `}
                    />
                  </Tooltip>
                )}
              </NavLink>
            ))}
          </CollapsibleList>
        ) : (
          <NavLink
            exact
            to={item.path}
            key={item.label}
            activeClassName="mdc-list-item--activated"
          >
            {show ? (
              <StyledItem
                graphic={item.icon ? iconNameToIcon[item.icon] : undefined}
                ripple={false}
                text={<StyledText>{item.label}</StyledText>}
              />
            ) : (
              <Tooltip content={item.label} align="right" showArrow>
                <StyledItem
                  graphic={item.icon ? iconNameToIcon[item.icon] : undefined}
                  ripple={false}
                  text=""
                  css={`
                    justify-content: center;
                  `}
                />
              </Tooltip>
            )}
          </NavLink>
        )
      )}
    </StyledList>
  );
}
