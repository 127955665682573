import { createGlobalStyle } from 'styled-components';
import '@material/theme/dist/mdc.theme.css';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    letter-spacing: 0.0312rem;
    background-color: #ffffff;
    font-family: var(--font-family);
  }

  ul {
    margin: 0;
    padding: 0;
  }

  :root {
    --nav-size: 80px;
    --nav-bg-color: white;
    --nav-border: 1px solid #e0e6ed;

    --body-background: #f3f2f1;
    --side-menu-background: #FAFBFC;

    --hover: rgb(244, 245, 247);

    --side-menu-width: 240px;
    --side-menu-padding: 8px;
    --side-menu-item-on-hover: #EBECF0;

    --theme-text-color: #009092;
    --is-working-indicator-size: 15px;
    --warning-attribute-value: #e7515a;
    --working: rgba(40, 167, 69, 0.7);

    --blue-chart: rgba(0, 123, 255, 0.7);
    --indigo-chart: rgba(102, 16, 242, 0.7);
    --red-chart: rgba(220, 53, 69, 0.7);
    --green-chart: rgba(40, 167, 69, 0.7);

    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #2E7D32;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family: 'ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


    --mdc-theme-primary: rgba(40, 167, 69, 0.7);
  }

  a {
    text-decoration: none;
    letter-spacing: 1px;

    &:hover {
      text-decoration: none;
    }
    &:active {
      outline:0;
      border:0;
    }
    &:focus {
      outline:0;
      border:0;
    }
  }
 button,input {
    &:hover {
      text-decoration: none;
    }
    &:active {
      outline:0;
      border:0;
    }
    &:focus {
      outline:0;
      border:0;
    }
  }

  .mdc-snackbar {
    top: 0;
    z-index: 2000;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;
