import React from 'react';
import { Icon } from '@rmwc/icon';
import styled from 'styled-components';

const StyledIcon = styled(Icon)<{
  icon: any;
}>`
  margin: 0;
`;

export const IconMapPin = (
  <StyledIcon
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-map-pin"
      >
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
        <circle cx="12" cy="10" r="3"></circle>
      </svg>
    }
  />
);
