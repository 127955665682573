import Text from '@Atomics/Text';
import { useLastData } from '@Organisms/AttributeCharts/useLastData';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { DialogContent } from '@rmwc/dialog';
import '@rmwc/dialog/styles';
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 10.622889084968895,
  lng: 107.19002209231991,
};

interface PointsMapProps {
  data: {
    markers: { uuid: string; lat: string; lng: string; device_code: string }[];
  };
}

function PointsMap({ data }: PointsMapProps) {
  const [pointId, setPointId] = useState<string | null>(null);
  const { data: pointDetailData } = useLastData({ pointId });
  // const [open, setOpen] = React.useState(false);
  const [infoWindowVisible, setInfoWindowVisible] = useState({});

  useEffect(() => {
    if (pointDetailData) {
      setInfoWindowVisible((prev) => ({
        [pointDetailData.data.point.uuid]: true,
      }));
    } else {
      setInfoWindowVisible({});
    }
  }, [pointDetailData]);

  // @ts-ignore
  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={7}
        options={{ disableDefaultUI: true }}
      >
        {(data.markers ?? []).map((point) => (
          <Marker
            key={point.device_code}
            position={{ lat: +point.lat, lng: +point.lng }}
            onClick={() => setPointId(point.uuid)}
          >
            {infoWindowVisible[point.uuid] ? (
              <InfoWindow onCloseClick={() => setPointId(null)}>
                <div>
                  <h2
                    css={`
                      margin: 0;
                      padding: 0 15px;
                    `}
                  >
                    <Text>Trạm: {pointDetailData?.data.point.name}</Text>
                  </h2>
                  <DialogContent
                    css={`
                      padding: 15px;
                    `}
                  >
                    {pointDetailData?.data.point_data.length &&
                      Object.values(
                        pointDetailData?.data.point.attributes ?? {}
                      ).map((att) => {
                        return (
                          <div key={att.id}>
                            <Text>{att.name + ': '}</Text>
                            <Text>
                              {
                                pointDetailData?.data.point_data[
                                  pointDetailData?.data.point_data.length - 1
                                ].data[att.id]
                              }
                            </Text>
                            <Text
                              css={`
                                padding-left: 4px;
                              `}
                            >
                              {' ' + att.unit}
                            </Text>
                          </div>
                        );
                      })}
                  </DialogContent>
                  <hr />
                  <Text>
                    {
                      pointDetailData?.data.point_data[
                        pointDetailData?.data.point_data.length - 1
                      ]?.created_at_vn
                    }
                  </Text>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
      {/* <Dialog
        open={open}
        onClose={(evt) => {

          setPointId(null);
        }}
        onClosed={(evt) => console.log(evt.detail.action)}
      >
        <DialogTitle>
          <Text>
            Trạm: {pointDetailData?.data.point.name} lúc{' '}
            {
              pointDetailData?.data.point_data[
                pointDetailData?.data.point_data.length - 1
              ]?.created_at_vn
            }
          </Text>
        </DialogTitle>
        <DialogContent>
          {pointDetailData?.data.point_data.length &&
            Object.values(pointDetailData?.data.point.attributes ?? {}).map(
              (att) => {
                return (
                  <div>
                    <Text>
                    {
                     att.name + ': '
                    }
                    </Text>
                  <Text>
                    {
                      pointDetailData?.data.point_data[
                        pointDetailData?.data.point_data.length - 1
                      ].data[att.id]
                    }
                  </Text>
                    <Text css={`padding-left:4px`}>
                    {
                     ' ' + att.unit
                    }
                    </Text>
                  </div>
                );
              }
            )}
        </DialogContent>
      </Dialog> */}
    </>
  );
}

export default PointsMap;
