import { useQuery } from 'react-query';
import { useClient } from '../context/auth-context';

function useFetchDashboard() {
  const client = useClient();

  const result = useQuery('dashboard', async () => {
    const res = await client('user/dashboard', {});
    return res;
  });

  return { ...result, data: result?.data?.data ?? {} };
}

export { useFetchDashboard };
