import { useQuery } from 'react-query';
import { useClient } from '../context/auth-context';

function useUserInfo() {
  const client = useClient();

  const result = useQuery('user', async () => {
    const res = await client('user', {});
    return res?.data;
  });

  return { ...result, data: result?.data ?? [] };
}

export { useUserInfo };
