import Text from '@Atomics/Text';
import React, {forwardRef, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from '../../common/useOnClickOutside';
import {useAuth} from '../../context/auth-context';
import {ReactComponent as SettingsIcon} from './settings.svg';
import {ReactComponent as BurgerIcon} from '../../components/burger.svg';
import TextLine from '@Atomics/Text/TextLine';
import {useMenu} from 'context/menu-context';
import { APPLICATION_NAME_DEFAULT, APPLICATION_COMPANY_DEFAULT } from 'constants/configs';

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;

const StyledNav = styled.nav`
  height: var(--nav-size);
  background-color: rgba(59, 130, 246);
  padding: 0 1rem;
  border-bottom: var(--nav-border);
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 4px;
    background: linear-gradient(180deg,
    rgba(9, 30, 66, 0.13) 0,
    rgba(9, 30, 66, 0.13) 1px,
    rgba(9, 30, 66, 0.08) 1px,
    rgba(9, 30, 66, 0) 4px);
  }
`;

const Ul = styled.ul`
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const A = styled.a`
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms ease-out;
  background-color: var(--nav-bg-color);

  // &:hover {
  //   background-color: var(--primary);
  //   svg {
  //     stroke: white;
  //   }
  // }

  svg {
    stroke: #081c15;
    stroke-width: 2;
  }
`;

const Li = styled.div`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

function NavItem(props: any) {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    useOnClickOutside(dropdownRef, () => setOpen(false), buttonRef);

    return (
        <Li>
            <A href="#" ref={buttonRef} onClick={(e) => e.preventDefault()}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                    }}
                >
                    {props.icon}
                </IconButton>
            </A>
            {open && props.children(dropdownRef)}
        </Li>
    );
}

const StyledDropdown = styled.div`
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 50px;
  width: 234px;
  right: 10px;
  background-color: var(--nav-bg-color);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
  0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  z-index: 1000;
`;

const StyledDropdownItem = styled.a`
  display: flex;
  align-items: flex-end;
  transition: background 100ms ease-out;
  padding: 8px 20px;
  background-color: var(--nav-bg-color);

  &:hover {
    background-color: var(--hover);
  }
`;

const StyledDropdownIcon = styled(IconButton)`
  margin-right: 6px;

  &:hover {
    background-color: var(--nav-bg-color);
  }
`;

function DropdownItem(props) {
    return (
        <StyledDropdownItem
            href="#"
            onClick={(e) => {
                e.preventDefault();
                props.onClick();
            }}
            {...props}
        >
            {props.leftIcon && (
                <StyledDropdownIcon>{props.leftIcon}</StyledDropdownIcon>
            )}
            {props.children}
        </StyledDropdownItem>
    );
}

const DropdownWithRef = forwardRef(function Dropdown(props: any, ref: any) {
    const {logout} = useAuth();

    return (
        <StyledDropdown ref={ref}>
            {/*<DropdownItem>*/}
            {/*  <StyledText>Thông tin tài khoản</StyledText>*/}
            {/*</DropdownItem>*/}
            <DropdownItem
                css={`
                  border-top: 2px solid rgba(9, 30, 66, 0.08);
                `}
                onClick={() => {
                    logout();
                }}
            >
                <StyledText>Đăng xuất</StyledText>
            </DropdownItem>
        </StyledDropdown>
    );
});

export default function Navbar({user}: any) {
    const {toggleMenu} = useMenu();

    return (
        <StyledNav>
            <BurgerIcon
                width="25"
                css={`
                  cursor: pointer;
                  color: #ffffff;
                  transition: all 250ms;

                  &:hover {
                    color: rgba(0, 0, 0, 0.7);
                  }
                `}
                onClick={() => {
                    toggleMenu();
                }}
            />
            <TextLine
                css={`
                  color: #ffffff;
                  font-size: 14px;
                  display: block;
                  text-align: center;
                  margin-left: 1rem;
                  margin-right: auto;
                  width: 100%;
                `}
            >
                {user?.company ? user.company : APPLICATION_COMPANY_DEFAULT}
                <Text
                    css={`
                      color: #ffffff;
                      font-weight: 600;
                      font-size: 17px;
                      display: block;
                      text-align: center;
                    `}
                >
                    {user?.application ? user.application : APPLICATION_NAME_DEFAULT}
                </Text>
            </TextLine>

            <Ul>
                <NavItem icon={<SettingsIcon/>}>
                    {(ref) => <DropdownWithRef ref={ref}/>}
                </NavItem>
            </Ul>
        </StyledNav>
    );
}
