import { usePaginatedQuery } from 'react-query';
import { useClient } from '../../context/auth-context';
import { UserReportResponse } from './types';

function useFetchUserReport({ start, end }: any) {
  const client = useClient();

  const startQuery = start ? `start=${start}` : '';
  const endQuery = end ? `end=${end}` : '';

  const result = usePaginatedQuery([`user/report`, start, end], async () => {
    const res = await client(`user/report?${startQuery}&${endQuery}`, {});
    return res as UserReportResponse;
  });

  return { ...result, data: result?.data };
}

export { useFetchUserReport };
