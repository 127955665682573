import Text from '@Atomics/Text';
import React from 'react';
import styled from 'styled-components';
import { Data } from './types';

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 70px;
  padding: 15px 20px 7px 20px;
  border-radius: 8px;
  background: #fff;
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }
`;

const InfoName = styled(Text)`
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0;
  margin-bottom: 0;
`;

const InfoBlock = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InfoValue = styled(Text)<{ data?: any }>`
  font-size: 32px;
  color: var(--theme-text-color);
  font-weight: 600;

  ${(props) =>
    !props.data
      ? `
    &::before {
      display: block;
      content: '0';
      font-size: 23px;
      opacity: 0;
    }
  `
      : ''}
`;

export function Summary(
  props: Pick<Data, 'total_point_online' | 'total_point_offline' | 'total_data'>
) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col pl-0">
          <SummaryWrapper>
            <InfoBlock>
              <InfoName>Tổng trạm online</InfoName>
              <InfoValue data={props.total_point_online}>
                {props.total_point_online}
              </InfoValue>
            </InfoBlock>
          </SummaryWrapper>
        </div>
        <div className="col">
          <SummaryWrapper>
            <InfoBlock>
              <InfoName>Tổng trạm offline</InfoName>
              <InfoValue data={props.total_point_online}>
                {props.total_point_offline}
              </InfoValue>
            </InfoBlock>
          </SummaryWrapper>
        </div>
        <div className="col">
          <SummaryWrapper>
            <InfoBlock>
              <InfoName>Tổng số data</InfoName>
              <InfoValue data={props.total_point_online}>
                {props.total_data}
              </InfoValue>
            </InfoBlock>
          </SummaryWrapper>
        </div>
      </div>
    </div>
  );
}
