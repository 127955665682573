import {DateRange} from '@blueprintjs/datetime';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import format from 'date-fns/format';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from 'rmwc';
import 'styled-components/macro';
import DateRangeInput from '../../components/DateRangeInput';
import './datetime.scss';
import {Table} from '@Components/Table';
import {useFetchPointTable} from './useFetchPointTable';
import Select from 'react-select';
import {usePointDataDownload} from './usePointDataDownload';

function PointDataTableAsync({pointId}: { pointId: number }) {
    const [page, setPage] = useState(1);
    const [dateRange, setDateRange] = useState<DateRange>();

    const [filtersOption, setFiltersOption] = useState<{
        start: any;
        end: any;
        dataType: any;
    }>();

    const {
        // isLoading,
        // isError,
        // error,
        resolvedData,
        latestData,
        isFetching: isLoading,
        refetch,
    } = useFetchPointTable({
        pointId,
        page,
        ...filtersOption,
    });

    const [filteredAttributes, setFilteredAttributes] = useState<number[] | null>(
        null
    );

    const fetchData = useCallback(({pageIndex, pageCount}) => {
        setPage(pageIndex);
    }, []);


    const dataTypeOptions = useMemo(() => {
        return [
            {
                label: 'Dữ liệu mặc định',
                value: null,
            },
            {
                label: 'Dữ liệu cuối ngày',
                value: 'last_value_of_the_day',
            },
            {
                label: 'Dữ liệu trung bình',
                value: 'last_value_agv',
            },
            {
                label: 'Dữ liệu lớn nhất',
                value: 'last_value_max',
            },
            {
                label: 'Dữ liệu nhỏ nhất',
                value: 'last_value_min',
            }
        ];
    }, []);

    const [dataType, setDataTypes] = useState<string | null // 'min' | 'max' | 'avg' | 'last_value_of_the_day'
        >(() => null);

    const columns = useMemo(
        () => [
            {
                Header: 'Thời gian',
                accessor: 'created_at_vn',
            },
            ...Object.values(resolvedData?.data?.point?.attributes ?? [])
                .filter(
                    (a) =>
                        !filteredAttributes ||
                        filteredAttributes.length === 0 ||
                        filteredAttributes.includes(a.id)
                )
                .map((a) => ({
                    Header: a.name + ' (' + a.unit + ')',
                    accessor: `${a.id}`,
                    Cell: ({value}) =>
                        value.value !== undefined ? String(value.value) : '',
                })),
        ],
        [resolvedData, filteredAttributes]
    );

    const data = useMemo(
        () =>
            (resolvedData?.data?.point_data?.data ?? []).map((i) => {
                const datum = {};

                for (const column of Object.keys(i.data)) {
                    datum[`${column}`] = {
                        is_warning:
                            resolvedData?.data?.point?.attributes &&
                            i.data[column] >
                            (resolvedData?.data?.point?.attributes[column].warnings ??
                                [])[0]?.value,
                        value: i.data[column],
                    };
                }
                return {
                    created_at_vn: i.created_at_vn,
                    ...datum,
                };
            }),
        [resolvedData]
    );

    const pointData = resolvedData?.data?.point_data;
    const canDownload = resolvedData?.data?.can_download;

    const pageCount = useMemo(() => pointData?.last_page ?? 0, [pointData]);

    const refetchHandler = () => {
        setFiltersOption({
            start:
                dateRange && format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
            end:
                dateRange && format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
            dataType: dataType
        });
    };

    const onDataTypeChange = useCallback(
        (selectedOption) => {
            setPage(1);
            setDataTypes(selectedOption ? selectedOption.value : selectedOption)
        },
        []
    );

    useEffect(() => {
        refetch();
    }, [refetch, filtersOption]);

    const options = useMemo(() => {
        return Object.values(resolvedData?.data.point.attributes || {}).map(
            (i) => ({
                label: i.name,
                value: i.id,
            })
        );
    }, [resolvedData]);

    const onSelectAttributesChange = useCallback(
        (selectedOptions) =>
            setFilteredAttributes(
                selectedOptions ? selectedOptions.map((s) => s.value) : selectedOptions
            ),
        []
    );

    const [handleDownloadClick] = usePointDataDownload();

    return (
        <section className="row">
            <div className="col">
                <div
                    css={`
                      margin-top: 21px;
                      flex: 1 0 auto;
                      max-width: 100%;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      padding: 15px 20px 7px 20px;
                      border-radius: 8px;
                      background: #fff;
                      box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
                      0 1px 20px 0 rgba(0, 0, 0, 0.07),
                      0px 1px 11px 0px rgba(0, 0, 0, 0.07);
                    `}
                >
                    <div
                        css={`
                          display: flex;
                          align-items: center;
                          width: 100%;
                          padding-bottom: 10px;
                          position: relative;
                        `}
                    >
                        <DateRangeInput dateRange={dateRange} setDateRange={setDateRange}/>
                        <Select
                            css={`
                              width: 200px;
                            `}
                            placeholder="Lọc theo thông số"
                            options={options}
                            onChange={onSelectAttributesChange}
                            isMulti
                        />
                        <Select
                            css={`
                              width: 230px;
                              margin-left: 10px;
                            `}
                            options={dataTypeOptions}
                            defaultValue={dataTypeOptions.find((o) => o.value === null)}
                            onChange={onDataTypeChange}
                            placeholder="Loại báo cáo"
                        />
                        <Button
                            css={`
                              margin-left: 10px;
                              margin-right: 10px;
                            `}
                            raised
                            theme={['primaryBg', 'onPrimary']}
                            label="Lọc"
                            ripple={false}
                            onClick={refetchHandler}
                        ></Button>
                        {canDownload ? (
                            <Button
                                css={`
                                  margin-left: auto;
                                `}
                                danger
                                raised
                                label="Download"
                                ripple={false}
                                onClick={() =>
                                    handleDownloadClick({
                                        start:
                                            dateRange &&
                                            format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                                        end:
                                            dateRange &&
                                            format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                                        pointId: pointId,
                                        dataType: dataType,
                                        filteredAttributes: filteredAttributes
                                    })
                                }
                            ></Button>
                        ) : (
                            ''
                        )}
                    </div>
                    {useMemo(
                        () =>
                            resolvedData && (
                                <Table
                                    showPaginate={true}
                                    columns={columns}
                                    data={data}
                                    pageCount={pageCount}
                                    fetchData={fetchData}
                                    loading={isLoading && !latestData}
                                />
                            ),
                        [
                            resolvedData,
                            columns,
                            data,
                            pageCount,
                            fetchData,
                            isLoading,
                            latestData,
                        ]
                    )}
                </div>
            </div>
        </section>
    );
}

export default PointDataTableAsync;
