import PageTitle from '@Atomics/PageTitle';
import React from 'react';
import { useQuery } from 'react-query';
import { PointDetailResponse } from '@Organisms/PointInfo/types';

function PointTitle({ pointId }: { pointId: number }) {
  const { data } = useQuery(['point', pointId, 'detail']) as {
    data: PointDetailResponse;
  };

  return (
    <PageTitle
      css={`
        padding-left: 0;
      `}
    >{`Chi tiết trạm ${data?.data?.name ?? ''}`}</PageTitle>
  );
}

export default PointTitle;
