import { useFetchPointDetail } from '@Organisms/PointInfo/useFetchPointDetail';
import React from 'react';
import 'styled-components/macro';
import Camera from './Camera';

export default function PointCamera({ pointId }: { pointId: number }) {
  const { data } = useFetchPointDetail({ pointId });

  return (
    <div>
      <div className="row mt-4">
        {data?.data.cameras.map((cam) => (
          <div key={cam.uuid} className="col col-6">
            <Camera camName={cam.name} camUid={cam.uuid} />
          </div>
        ))}
      </div>
    </div>
  );
}
