import { queryCache } from 'react-query';
import * as auth from './auth';
import { queue } from './common/notification';
import { API_URL } from './constants/configs';

const apiURL = API_URL;

async function client(
  endpoint,
  // @ts-ignore
  { data, token, headers: customHeaders = {}, ...customConfig } = {}
) {
  let config = {};

  if (data && data instanceof FormData) {
    config = {
      method: 'POST',
      mode: 'cors',
      body: data,
      headers: {
        authorization: token ? `Bearer ${token}` : undefined,
        ...customHeaders,
      },
      ...customConfig,
    };
  } else {
    config = {
      method: data ? 'POST' : 'GET',
      mode: 'cors',
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        authorization: token ? `Bearer ${token}` : undefined,
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      ...customConfig,
    };
  }

  return (
    window
      // @ts-ignore
      .fetch(`${apiURL}/${endpoint}`, config)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 401) {
          queryCache.clear();
          await auth.logout();
          // refresh the page for them
          // @ts-ignore
          window.location.assign(window.location);
          return Promise.reject({ message: 'Vui lòng đăng nhập lại' });
        }
        if (response.ok) {
          return data;
        } else {
          queue.notify({
            body: data?.message ? data?.message : 'Lỗi vui lòng thử lại',
            dismissesOnAction: true,
            actions: [
              {
                title: 'Đóng',
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.error(err);
        queue.notify({
          body: 'Lỗi vui lòng thử lại',
          dismissesOnAction: true,
          actions: [
            {
              title: 'Đóng',
            },
          ],
        });
      })
  );
}

export { client };
