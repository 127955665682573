import React from 'react';
import styled from 'styled-components';
import Parser from 'html-react-parser';

const FooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  background: #1b55e2;
  text-align: center;
  font-size: 12px;
  padding: 5px;

  a {
    color: white;
    font-weight: bold;
  }
`;
export default function Footer({
                                   text = 'Hệ thống quan trắc online <br>Hello',
                               }) {
    return <FooterContainer>{Parser(text)}</FooterContainer>;
}
