import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from '../src/styles/global.styled';
import { AppProviders } from './context';
import './index.scss';
import { SnackbarQueue } from 'rmwc';
import { queue } from './common/notification';
import '@rmwc/snackbar/styles';

// import { ReactQueryDevtools } from 'react-query-devtools';

ReactDOM.render(
  // <React.StrictMode>
  // <React.StrictMode>
  <>
    {/* <ReactQueryDevtools /> */}
    <AppProviders>
      <GlobalStyle />
      <App />
      <SnackbarQueue messages={queue.messages} />
    </AppProviders>
  </>,

  // </React.StrictMode>
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
