import { useMenu } from 'context/menu-context';
import React from 'react';
import { useFetchPoints } from '../../api/points';
import SideMenu, {
  ICON_HOME,
  ICON_MAP,
  ICON_MAP_BIG,
  ICON_MAP_PIN,
  ICON_REPORT,
} from './SideMenu';

const initialData = {
  items: [
    {
      label: 'Trang chủ',
      path: '/',
      icon: ICON_HOME,
    },
    {
      label: 'Báo cáo',
      path: '/report',
      icon: ICON_REPORT,
    },
    {
      label: 'Bản đồ',
      path: '/map',
      icon: ICON_MAP_BIG,
    },
    {
      label: 'Danh sách trạm',
      path: '/point',
      items: [],
      icon: ICON_MAP,
    },
  ],
};

export default function SideMenuAsync() {
  const { data } = useFetchPoints();
  const { showMenu } = useMenu();
  return (
    <SideMenu
      show={showMenu}
      data={
        !data
          ? initialData
          : {
              ...initialData,
              items: initialData.items.map((item) => {
                if (item.path === '/point') {
                  return {
                    ...item,
                    items: data.map((p) => ({
                      label: p.name,
                      path: `/point/${p.uuid}`,
                      icon: ICON_MAP_PIN,
                    })),
                  };
                }
                return item;
              }),
            }
      }
    />
  );
}
