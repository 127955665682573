import React from 'react';
import { Icon } from '@rmwc/icon';
import styled from 'styled-components';

const StyledIcon = styled(Icon)<{
  icon: any;
}>`
  margin: 0;
`;

export const IconMap = (
  <StyledIcon
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="feather feather-map"
        viewBox="0 0 24 24"
      >
        <path d="M1 6L1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6z"></path>
        <path d="M8 2L8 18"></path>
        <path d="M16 6L16 22"></path>
      </svg>
    }
  />
);
