import React from 'react';
import ReactPaginate from 'react-paginate';
import { ReactComponent as LeftArrow } from './leftArrow.svg';
import { ReactComponent as RightArrow } from './rightArrow.svg';
import { ReactComponent as More } from './more.svg';
import 'styled-components/macro';

function Paginate(props) {
  return (
    <div
      css={`
        margin-top: 10px;
        background-color: white;

        .pagination {
          margin: 3px 0;
          white-space: nowrap;
          justify-content: flex-end;
          display: flex;
          padding-left: 0;
          list-style: none;
          border-radius: 0.25rem;
          color: #888ea8;
          height: 100%;
          font-size: 0.875rem;
          background: white;
          letter-spacing: 0.0312rem;
          font-family: 'Nunito', sans-serif;

          > li {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 17px;
              height: 17px;
              transition: 100ms ease-out;
            }

            &:hover:not(.disabled) {
              svg {
                color: #1b55e2;
                fill: rgba(27, 85, 226, 0.23921568627450981);
              }
            }

            > a {
              cursor: pointer;
              margin-right: 5px;
              border-radius: 50%;
              padding: 8px;
              min-width: 34px;
              height: 34px;
              background: rgba(0, 23, 55, 0.08);
              border: none;
              position: relative;
              display: block;
              line-height: 1.25;
              text-align: center;
            }

            &.active {
              > a {
                background-color: #1b55e2;
                z-index: 3;
                color: #fff;
                background-color: #007bff;
                border-color: #007bff;
              }
            }
          }
        }
      `}
    >
      <ReactPaginate
        {...props}
        previousLabel={<LeftArrow />}
        nextLabel={<RightArrow />}
        breakLabel={<More />}
      />
    </div>
  );
}

export default Paginate;
