import React, {useMemo} from 'react';
import ErrorFallback from '@Components/ErrorFallback';
import {AttributeCharts} from './AttributeCharts';
import {PointDataTableAsync} from './DataTable';
import {PointCamera} from './PointCamera';
import PointChartAsync from './PointChart/PointChartAsync';
import {PointInfo} from './PointInfo';
import {PointTitle} from './PointTitle';
import {useParams} from 'react-router-dom';

export default function PointDetailPage() {
    const {pointId} = useParams();
    return useMemo(
        () => (
            <ErrorFallback key={pointId}>
                <div className="container-fluid px-4 py-4">
                    <PointTitle pointId={pointId}/>
                    <AttributeCharts pointId={pointId}/>
                    <PointInfo pointId={pointId}/>
                    <PointChartAsync pointId={pointId}/>
                    <PointCamera pointId={pointId}/>
                    <PointDataTableAsync pointId={pointId}/>
                </div>
            </ErrorFallback>
        ),
        [pointId]
    );
}
