import {Navbar} from '@Organisms/Navbar';
import {MenuProvider} from 'context/menu-context';
import React from 'react';
import styled from 'styled-components';
import Footer from "@Organisms/Footer";
import {FOOTER1} from "./constants/configs";

const StyledBody = styled.section`
  display: flex;
`;

const StyleMain = styled.div`
  margin-top: var(--nav-size);
  margin-right: 0;
  height: calc(100vh - var(--nav-size));
  max-height: calc(100vh - var(--nav-size));
  flex-grow: 1;
  overflow-y: auto;
`;

function Layout(props: any) {
    return (
        <MenuProvider>
            <Navbar user={props.user}/>
            <StyledBody>
                {props.sidebar}
                <StyleMain>{props.children}</StyleMain>
            </StyledBody>
            <Footer text={props.user?.footer ? props.user?.footer : FOOTER1} />
        </MenuProvider>
    );
}

export default Layout;
