import React from 'react';
import { useAsync } from 'react-async';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerSvg } from './spinner.svg';

const Form = styled.form`
  padding: 20px;
`;

const FormGroup = styled.div`
  padding: 11px 0px 16px 0;
  border-bottom: none;
  position: relative;
  text-align: left;
`;
const Label = styled.label`
  font-size: 13px;
  font-weight: 700;
  color: #3b3f5c;
  margin-bottom: 8px;
  text-align: left;
`;
const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 200ms ease-in-out, box-shadow 200ms ease-in-out;

  border: 1px solid #bfc9d4;
  color: #3b3f5c;
  font-size: 14px;
  letter-spacing: 1px;
  height: calc(1.4em + 1.4rem + 2px);
  padding: 0.75rem 1.25rem;
  border-radius: 6px;

  &:focus {
    border: 1px solid #1b55e2;
    box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.6196078431372549);
    color: #495057;
    background-color: #fff;
    outline: 0;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(SpinnerSvg)`
  margin-left: 6px;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s infinite linear;
`;

function LoginForm({ onSubmit, submitButton }) {
  const { isPending, error, run } = useAsync({
    deferFn: ([loginInfo]) => onSubmit(loginInfo),
  });

  function handleSubmit(event) {
    event.preventDefault();
    const { email, password } = event.target.elements;

    run({
      email: email.value,
      password: password.value,
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Địa chỉ email:</Label>
        <Input id="email" type="email" required placeholder="Email" />
      </FormGroup>
      <FormGroup>
        <Label>Mật khẩu:</Label>
        <Input id="password" type="password" required placeholder="Password" />
      </FormGroup>
      <div>
        {React.cloneElement(
          submitButton,
          { type: 'submit' },
          ...(Array.isArray(submitButton.props.children)
            ? submitButton.props.children
            : [submitButton.props.children]),
          isPending ? <Spinner /> : null
        )}
      </div>
      {error ? <p> {error.message} </p> : null}
    </Form>
  );
}

export { LoginForm };
