// pretend this is firebase, netlify, or auth0's code.
// you shouldn't have to implement something like this in your own app

import {API_URL} from './constants/configs';

const localStorageKey = '__auth_provider_token__';
const localStorageUserInfo = '__auth_user_info__';
const authURL = API_URL;

async function getToken() {
    // if we were a real auth provider, this is where we would make a request
    // to retrieve the user's token. (It's a bit more complicated than that...
    // but you're probably not an auth provider so you don't need to worry about it).
    return window.localStorage.getItem(localStorageKey);
}


function handleUserResponse({access_token, info}) {
    window.localStorage.setItem(localStorageKey, access_token);
    window.localStorage.setItem(localStorageUserInfo, JSON.stringify(info));
    return {
        token: access_token,
        user_info: info,
    };
}

function login({email, password}) {
    return client('login', {email, password}).then(handleUserResponse);
}

async function logout() {
    window.localStorage.removeItem(localStorageKey);
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client

async function client(endpoint, data) {
    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'},
    };

    return window
        .fetch(`${authURL}/${endpoint}`, config)
        .then(async (response) => {
            const data = await response.json();
            if (response.ok) {
                return data;
            } else {
                return Promise.reject(data);
            }
        });
}

export {getToken, login, logout, localStorageKey};
