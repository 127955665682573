import React from 'react';
import styled from 'styled-components';
import { Point as PointProps } from './types';
import Text from '@Atomics/Text';

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  width: 100%;
`;

const AttributesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
`;

const AttributeWrapper = styled.div<{ isWarning: boolean }>`
  margin-top: 12px;
  margin-right: 12px;
  padding: 15px 20px 7px 20px;
  border-radius: 8px;
  border: 2px solid
    ${(props) => (props.isWarning ? `var(--warning-attribute-value)` : 'green')};
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
`;

const PointName = styled(Text)`
  font-size: 19px;
  color: #0e1726;
  font-weight: 700;
`;

const PointNameBlock = styled.div`
  margin-bottom: 9px;
`;

const AttributeName = styled(Text)`
  font-weight: 700;
  font-size: 19px;
  color: #515365;
`;

const AttributeLastValue = styled(Text)<{ isWarning: boolean }>`
  display: inline-block;
  margin-right: 9px;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  ${(props) =>
    props.isWarning ? `color: var(--warning-attribute-value);` : ''}
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 9px;
  padding-bottom: 9px;
  border-bottom: 1px dashed #bfc9d4;
`;

const AttributePropertyLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

const AttributeProperty = styled(Text)`
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 14px;
  color: #515365;
  display: inline-block;
  margin-right: 9px;
`;

const AttributePropertyValue = styled(Text)`
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 14px;
  color: #888ea8;
`;

const MinMaxBlock = styled.div`
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AttributeLastValueBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AttributeLastValueUnit = styled(Text)`
  font-size: 14px;
  display: block;
`;

const IsWorkingIndicator = styled.div<{ isWorking: boolean }>`
  display: inline-block;
  width: var(--is-working-indicator-size);
  height: var(--is-working-indicator-size);
  min-width: var(--is-working-indicator-size);
  min-height: var(--is-working-indicator-size);
  border-radius: 50%;
  margin-right: 9px;
  background-color: ${(props) =>
    props.isWorking ? 'var(--working)' : 'var(--warning-attribute-value)'};
`;

export function PointCard(props: PointProps) {
  return (
    <PointWrapper>
      <PointNameBlock>
        <PointName>Trạm: {props.name}</PointName>
      </PointNameBlock>
      <AttributesWrapper>
        {Object.entries(props.attributes).map(([key, attribute]) => (
          <AttributeWrapper
            key={key}
            isWarning={
              attribute?.last?.value_no_unit > attribute.warnings[0]?.value
            }
          >
            <Heading>
              <AttributeName>
                <IsWorkingIndicator isWorking={!!attribute.is_working} />
                {attribute.name}
              </AttributeName>
              <AttributeLastValueBlock>
                <AttributeLastValue
                  isWarning={
                    attribute?.last?.value_no_unit >=
                    attribute.warnings[0]?.value
                  }
                >
                  {attribute?.last?.value_no_unit}
                </AttributeLastValue>
                <AttributeLastValueUnit>
                  {attribute.unit}
                </AttributeLastValueUnit>
              </AttributeLastValueBlock>
            </Heading>
            <MinMaxBlock>
              <AttributePropertyLine>
                <AttributeProperty>Max</AttributeProperty>
                <AttributePropertyValue>
                  {attribute?.max?.value_no_unit}
                </AttributePropertyValue>
              </AttributePropertyLine>
              <AttributePropertyLine>
                <AttributeProperty>Min</AttributeProperty>
                <AttributePropertyValue>
                  {attribute?.min?.value_no_unit}
                </AttributePropertyValue>
              </AttributePropertyLine>
            </MinMaxBlock>
            <AttributePropertyLine>
              <AttributeProperty>Giá trị cảnh báo</AttributeProperty>
              <AttributePropertyValue>
                {attribute.warnings.map((warning) => warning.value).join(' < ')}
              </AttributePropertyValue>
            </AttributePropertyLine>
          </AttributeWrapper>
        ))}
      </AttributesWrapper>
    </PointWrapper>
  );
}
