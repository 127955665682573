import { useQuery } from 'react-query';
import { useClient } from '../../context/auth-context';
import { PointDetailResponse } from './types';

function useFetchPointDetail({ pointId }, queryOptions?: any) {
  const client = useClient();

  const result = useQuery(
    ['point', pointId, 'detail'],
    async () => {
      const res = await client(`point/${pointId}/detail`, {});
      return res as PointDetailResponse;
    },
    {
      enabled: pointId,
    }
  );

  return { ...result, data: result?.data ?? null };
}

export { useFetchPointDetail };
