import React, { useMemo } from 'react';
import 'styled-components/macro';
import PointChartAmChart from './PointChartAmChart';
import { useFetchPointChart } from './useFetchPointChart';
import DelayedRendered from '@Components/DelayedRender';

const mapChartType = {
  LineChart: 'LineSeries' as const,
  ColumnChart: 'ColumnSeries' as const,
};

const otherColors = ['#20c997', '#6610f2', '#17a2b8'];

function PointChartAsync({ pointId }: { pointId: number }) {
  const { data } = useFetchPointChart({
    pointId,
  });

  const seriesData = useMemo(() => {
    const attributes = Object.values(data?.data?.point?.attributes || {});

    const serieData = [] as any[];
    for (let i = 0; i < (data?.data.point_data.length || 0); i++) {
      const datum = {
        date: new Date(data?.data.point_data[i].created_at as any),
      };
      for (const attribute of attributes) {
        datum[attribute.id] = data?.data.point_data[i].data[attribute.id];
      }
      serieData.push(datum);
    }
    return serieData;
  }, [data]);

  const attributes = useMemo(() => {
    return Object.values(data?.data.point.attributes ?? {}).map((att) => ({
      ...att,
      type: mapChartType[att.type_chart_name],
      warnings: (att.warnings ?? []).map((att) => att.value),
      color: att.color ?? otherColors.pop(),
    }));
  }, [data]);

  const pointData = {
    attributes: attributes.map(({ warnings, ...att }) => att),
    seriesData,
  };

  return useMemo(
    () => (
      <>
        <section className="row mt-4">
          <div className="col">
            <DelayedRendered
              delay={250}
              waitFor={!!data && seriesData.length > 0}
              height={552}
            >
              <PointChartAmChart
                chartId="main"
                data={pointData}
                labelsEnabled={true}
              />
            </DelayedRendered>
          </div>
        </section>
        <section className="row">
          {attributes.map((att) => (
            <div className="col col-6 mt-4" key={att.id}>
              <DelayedRendered
                delay={250}
                waitFor={!!data && seriesData.length > 0}
                height={552}
              >
                <PointChartAmChart
                  chartId={`${att.id}`}
                  data={{ attributes: [att], seriesData }}
                  labelsEnabled={false}
                />
              </DelayedRendered>
            </div>
          ))}
        </section>
      </>
    ),
    [attributes, data, pointData, seriesData]
  );
}

export default PointChartAsync;
